<template>
  <div>
    <div class="u-bottom-margin--5x">
      <div class="u-bottom-padding--0 u-font--bold u-font--large">
        Marketing Section
      </div>
    </div>
    <el-tabs type="card" @tab-click="handleClick">
      <el-tab-pane label="Top Deals">
        <div>
          <div>
            <el-select v-model="pla" placeholder="Select Area">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              class="u-left-margin--1x u-right-margin--1x"
              v-model="pla"
              placeholder="Select Product"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button type="primary" @click="assiginTopDeal"
              >Assigin Top deal
            </el-button>
          </div>
          <div>
            <el-table :data="fproducts" border stripe>
              <el-table-column prop="product" label="product" width="100">
              </el-table-column>
              <el-table-column prop="Area" label="Area" width="200">
              </el-table-column>

              <el-table-column prop="opertaions" label="opertaions">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Featured Products">
        <el-button
          class="u-bottom-margin--1x"
          type="primary"
          @click="assginFProduct"
          size="mini"
          >assgin new product</el-button
        >
        <el-table :data="fproducts" border stripe>
          <el-table-column prop="product ID " label="product ID" width="100">
          </el-table-column>
          <el-table-column prop="Area" label="Area" width="200">
          </el-table-column>
          <el-table-column prop="image" label="featured image" width="200">
          </el-table-column>
          <el-table-column prop="inHome" label="in home" width="200">
          </el-table-column>
          <el-table-column prop="in category" label="in category" width="200">
          </el-table-column>
          <el-table-column prop="opertaions" label="opertaions">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Ads">
        <el-button
          class="u-bottom-margin--1x"
          type="primary"
          @click="createAd"
          size="mini"
          >Create new Ad</el-button
        >
        <el-table :data="fproducts" border stripe>
          <el-table-column prop="id" label="id" width="100"> </el-table-column>
          <el-table-column prop="uri" label="uri" width="200">
          </el-table-column>

          <el-table-column prop="visable" label="visable"> </el-table-column>
          <el-table-column prop="type" label="type"> </el-table-column>
          <el-table-column
            prop="opertaions"
            label="opertaions"
          ></el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  methods: {
    data() {
      return {
        fproducts: [],
      };
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    assginFProduct() {
      this.$router.push("/marketing/assginFeauturedProduct");
    },
    createAd() {
      this.$router.push("/marketing/createAdPage");
    },
  },
};
</script>

<style></style>
